import React, { createContext, useReducer, useContext } from 'react';

export const OpenJobsContext = createContext(null);
export const OpenJobsDispatchContext = createContext(null);

const initialState = {
  jobcodefilter: '',
  clientfilter: '',
  namefilter: '',
  repfilter: '',
  agentfilter: '',
  corpfilter: '',
  divfilter: '',
  classfilter: '',
  billingtypefilter: '',
};

export const types = {
  SET_JOBCODE_FILTER: 'SET_JOBCODE_FILTER',
  SET_CLIENT_FILTER: 'SET_CLIENT_FILTER',
  SET_NAME_FILTER: 'SET_NAME_FILTER',
  SET_REP_FILTER: 'SET_REP_FILTER',
  SET_AGENT_FILTER: 'SET_AGENT_FILTER',
  SET_CORP_FILTER: 'SET_CORP_FILTER',
  SET_DIV_FILTER: 'SET_DIV_FILTER',
  SET_CLASS_FILTER: 'SET_CLASS_FILTER',
  SET_BILLING_TYPE_FILTER: 'SET_BILLING_TYPE_FILTER',
};

const openJobsReducer = (openJobs, action) => {
  switch (action.type) {
    case types.SET_JOBCODE_FILTER:
      return {
        ...openJobs,
        jobcodefilter: action.payload,
      };
    case types.SET_CLIENT_FILTER:
      return {
        ...openJobs,
        clientfilter: action.payload,
      };
    case types.SET_NAME_FILTER:
      return {
        ...openJobs,
        namefilter: action.payload,
      };
    case types.SET_REP_FILTER:
      return {
        ...openJobs,
        repfilter: action.payload,
      };
    case types.SET_AGENT_FILTER:
      return {
        ...openJobs,
        agentfilter: action.payload,
      };
    case types.SET_CORP_FILTER:
      return {
        ...openJobs,
        corpfilter: action.payload,
      };
    case types.SET_DIV_FILTER:
      return {
        ...openJobs,
        divfilter: action.payload,
      };
    case types.SET_CLASS_FILTER:
      return {
        ...openJobs,
        classfilter: action.payload,
      };
    case types.SET_BILLING_TYPE_FILTER:
      return {
        ...openJobs,
        billingtypefilter: action.payload,
      };
    default:
      throw new Error('Unexpected action');
  }
};

export const OpenJobsProvider = ({ children }) => {
  const [openJobs, dispatch] = useReducer(openJobsReducer, initialState);

  return (
    <OpenJobsContext.Provider value={openJobs}>
      <OpenJobsDispatchContext.Provider value={dispatch}>
        {children}
      </OpenJobsDispatchContext.Provider>
    </OpenJobsContext.Provider>
  );
};

export const useOpenJobsState = () => {
  return useContext(OpenJobsContext);
};

export const useOpenJobsStateDispatch = () => {
  return useContext(OpenJobsDispatchContext);
};
