import React, { createContext, useContext, useReducer } from 'react';
import moment from 'moment';
import {
  getColorfromJobCategory,
  addTzShiftToDatestring,
  subtractTzShiftFromDatestring,
} from '../helpers';
import S from '../styles';

export const TimeEntriesContext = createContext(null);
export const TimeEntriesDispatchContext = createContext(null);

const initialTimeEntries = [];

const timeEntriesReducer = (timeEntries, action) => {
  switch (action.type) {
    // expects payload.action to be a single time entry
    case 'add': {
      if (action.payload?.isFiller) {
        return setOverlappingEventsOrange([
          ...timeEntries,
          {
            ...action.payload,
          },
        ]);
      } else {
        return setOverlappingEventsOrange([
          ...timeEntries.filter(x => !x?.isFiller),
          {
            ...action.payload,
            color: getColorfromJobCategory(action.payload.job_category),
          },
        ]);
      }
    }

    case 'updateTZ': {
      return timeEntries.map(r => {
        return {
          ...r,
          start: r.utc_start
            ? addTzShiftToDatestring(r.utc_start, action.timezoneData)
            : r.start,
          end: r.utc_end
            ? addTzShiftToDatestring(r.utc_end, action.timezoneData)
            : r.end,
        };
      });
    }

    case 'showUpdating': {
      return timeEntries.map(e => {
        if (e.isUpdating) {
          return {
            ...e,
            color: getColorfromJobCategory(e.job_category),
            isUpdating: false,
          };
        } else {
          return e;
        }
      });
    }

    case 'updateLabel': {
      const dataset = action.payload;

      return timeEntries.map(e => {
        if (e.isUpdating) {
          return {
            ...e,
            isUpdating: false,
            color: getColorfromJobCategory(dataset.job_category),
            title: 'Updating...',
            annotation: '',
          };
        } else {
          return e;
        }
      });
    }

    case 'resizeOrDrop': {
      // expects action.payload to be a single time entry
      return setOverlappingEventsOrange(
        timeEntries.map(e => {
          if (e.id === action.payload.id) {
            return {
              ...action.payload,
              color: getColorfromJobCategory(action.payload.job_category),
            };
          } else {
            return e;
          }
        })
      );
    }

    case 'updateWhileDragging': {
      const start = subtractTzShiftFromDatestring(
        action.start,
        action.timezoneData
      );

      return timeEntries.map(e => {
        if (moment(start) >= moment(e.start) && moment(start) < moment(e.end)) {
          return { ...e, color: S.c_green_button, isUpdating: true };
        } else {
          return {
            ...e,
            color: getColorfromJobCategory(e.job_category),
            isUpdating: false,
          };
        }
      });
    }

    // A disgusting hack to make the ghost entry disappear on days that have no time entries
    case 'initialLoad': {
      if (Object.keys(action.payload[0]).length === 0) {
        return [{ color: 'transparent' }];
      }
      return setOverlappingEventsOrange(
        action.payload.map(r => {
          return {
            ...r,
            color: getColorfromJobCategory(r.job_category),
            start: addTzShiftToDatestring(r.utc_start, action.timezoneData),
            end: addTzShiftToDatestring(r.utc_end, action.timezoneData),
          };
        })
      );
    }
    default: {
      return timeEntries;
    }
  }
};

// Wrap the app in this provider to give all children access
export const TimeEntryProvider = ({ children }) => {
  const [timeEntries, dispatch] = useReducer(
    timeEntriesReducer,
    initialTimeEntries
  );

  return (
    <TimeEntriesContext.Provider value={timeEntries}>
      <TimeEntriesDispatchContext.Provider value={dispatch}>
        {children}
      </TimeEntriesDispatchContext.Provider>
    </TimeEntriesContext.Provider>
  );
};

export const useTimeEntries = () => {
  return useContext(TimeEntriesContext);
};

export const useTimeEntriesDispatch = () => {
  return useContext(TimeEntriesDispatchContext);
};

// Call before any event update so that overlapping events will be orange
const setOverlappingEventsOrange = currEvents => {
  return currEvents.map(e1 => {
    let overlapping = false;
    currEvents.forEach(e2 => {
      if (e2.id !== e1.id) {
        if (
          (moment(e1.start) < moment(e2.start) &&
            moment(e1.end) > moment(e2.start)) ||
          (moment(e1.end) > moment(e2.end) &&
            moment(e1.start) < moment(e2.end)) ||
          (moment(e1.start)?._i === moment(e2.start)?._i &&
            moment(e1.end)?._i === moment(e2.end)?._i)
        ) {
          overlapping = true;
        }
      }
    });

    if (overlapping) {
      return { ...e1, color: S.c_orange };
    } else {
      return { ...e1, color: getColorfromJobCategory(e1.job_category) };
    }
  });
};
