import React, { createContext, useContext, useReducer } from 'react';
import moment from 'moment';

export const GeneralStateContext = createContext(null);
export const GeneralStateDispatchContext = createContext(null);

const initialGeneralStateContext = {
  dashTasksActiveColumn: 'client_abbrev',
  dashTasksSortDirection: 'asc',
  dashJobsActiveColumn: 'last_used',
  dashJobsSortDirection: 'desc',
  myTasksExpansion: '',
  deptTasksExpansion: '',
  myReceivablesExpansion: '',
  totalTasks: 0,
  totalReceivables: 0,
  calendarDate: moment().format('YYYY-MM-DD'),
  taskColorBarsToUpdate: [],
  jobColorBarsToUpdate: [],
};

const generalStateReducer = (generalState, action) => {
  switch (action.type) {
    case 'updateTasksActiveColumn': {
      return { ...generalState, dashTasksActiveColumn: action.payload };
    }
    case 'updateTasksSortDirection': {
      return { ...generalState, dashTasksSortDirection: action.payload };
    }
    case 'updateJobsActiveColumn': {
      return { ...generalState, dashJobsActiveColumn: action.payload };
    }
    case 'updateJobsSortDirection': {
      return { ...generalState, dashJobsSortDirection: action.payload };
    }
    case 'setTotalTasks': {
      return { ...generalState, totalTasks: action.payload };
    }
    case 'setTotalReceivables': {
      return { ...generalState, totalReceivables: action.payload };
    }
    case 'setMyTasksExpansion': {
      return { ...generalState, myTasksExpansion: action.payload };
    }
    case 'setDeptTasksExpansion': {
      return { ...generalState, deptTasksExpansion: action.payload };
    }
    case 'setMyReceivablesExpansion': {
      return { ...generalState, myReceivablesExpansion: action.payload };
    }
    case 'updateCalendarDate': {
      return { ...generalState, calendarDate: action.payload };
    }
    case 'setJobColorBarsToUpdate': {
      return { ...generalState, jobColorBarsToUpdate: action.payload };
    }
    case 'setTaskColorBarsToUpdate': {
      return { ...generalState, taskColorBarsToUpdate: action.payload };
    }
    default: {
      return generalState;
    }
  }
};

export const GeneralStateProvider = ({ children }) => {
  const [generalState, dispatch] = useReducer(
    generalStateReducer,
    initialGeneralStateContext
  );

  return (
    <GeneralStateContext.Provider value={generalState}>
      <GeneralStateDispatchContext.Provider value={dispatch}>
        {children}
      </GeneralStateDispatchContext.Provider>
    </GeneralStateContext.Provider>
  );
};

export const useGeneralState = () => {
  return useContext(GeneralStateContext);
};

export const useGeneralStateDispatch = () => {
  return useContext(GeneralStateDispatchContext);
};
