// This file is used for exporting global css variables.
// Colors begin with 'c_'
// Heights begin with 'h_'
// Widths begin with 'w_'

const S = {
  c_bg: '#EAF2F6',
  c_blue_dark: '#32404F',
  // c_blue_dark: '#87BAAB',
  c_blue_dark_hover: '#50667d',
  c_blue_highlight: '#bfedfd',
  c_blue_light: '#63838c',
  c_blue_type: '#0179ad',
  c_border: '#cecece',
  c_colorbar_green:
    '-webkit-linear-gradient(top,RGBA(85,232,40,.1) 0,RGBA(85,232,40,.4) 100%)',
  c_colorbar_red:
    '-webkit-linear-gradient(top,RGBA(229,60,34,.1) 0,RGBA(229,60,34,.4) 100%)',
  c_colorbar_yellow:
    '-webkit-linear-gradient(top,RGBA(222,203,17,.1) 0,RGBA(222,203,17,.4) 100%)',
  c_error: '#FF4B38',
  c_gray_dark: '#2A2C2E',
  c_gray_highlight: '#cdcdcd',
  c_gray_light: '#CECECE',
  c_gray_type: '#5f666e',
  c_green_button: '#5fcf80',
  c_green_button_highlight: '#91DEA8',
  c_green_highlight: '#d9eaca',
  c_green_overage: 'rgb(11, 156, 49, 0.7)',
  c_green_type: '#57912b',
  c_orange: '#FF4B38',
  // c_orange: '#B27092',
  c_purple_dark_highlight: '#ccccff',
  c_purple_dark_type: '#6633ff',
  c_purple_highlight: '#cbdbfd',
  c_purple_type: '#073ac7',
  c_red_highlight: '#f6c7d9',
  c_red_type: '#af114b',
  c_red_warning: 'rgb(255, 0, 0, 0.7)',
  c_type: '#374657',
  c_white: '#fff',
  c_white_almost: '#f5f5f5',
  c_yellow_caution: 'rgb(222, 203, 17, 1)',
  c_yellow_highlight: '#ffe8cc',
  c_yellow_type: '#ff9900',

  // Job/time category colors
  c_client_work: '#004080',
  c_client_work_hover: '#00274d',
  c_business_development: '#016fda',
  c_intellectual_property: '#77afdb',
  c_support: '#d6cfc6',
  c_unassigned_hours: '#FF4B38',
  c_untracked_hours: '#a9a9a9',

  f_app: `"pt-sans-pro-narrow", sans-serif`,
  f_bold: 600,
  f_extra_light: 200,
  f_heading: `"bebas-neue-by-fontfabric", sans-serif`,
  f_light: 300,
  f_regular: 400,
  f_semi_bold: 500,

  h_nav: '60px',
  h_status_bar: '60px',

  w_default: '96%',
  w_max: '1600px',
};

export default S;
