import React, { createContext, useContext, useReducer } from 'react';
import { dynamicSort } from '../helpers';

export const TasksContext = createContext(null);
export const TasksDispatchContext = createContext(null);

// Initial state is an empty array
const initialTasksContext = [];

const tasksReducer = (tasks, action) => {
  switch (action.type) {
    // Expects an object {column: value, direction: value} as the payload
    case 'sort': {
      return dynamicSort(
        dynamicSort(tasks, 'priority', 'desc'),
        action.payload.column,
        action.payload.direction
      );
    }
    // Expects an array of tasks as the payload
    case 'setTasks': {
      return action.payload;
    }
    // Expects a taskId in the action and a single task object as the payload
    case 'updateTask': {
      return tasks.map(task => {
        if (task.id === Number(action.taskId)) {
          return { ...action.payload };
        } else {
          return task;
        }
      });
    }
    // Expects a taskId in the action
    case 'removeTask': {
      return tasks.filter(t => t.id !== Number(action.taskId));
    }
    // Expects a task object as the payload
    case 'addTask': {
      return [...tasks, { ...action.payload }];
    }
    default: {
      return tasks;
    }
  }
};

export const TasksProvider = ({ children }) => {
  const [tasks, dispatch] = useReducer(tasksReducer, initialTasksContext);

  return (
    <TasksContext.Provider value={tasks}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksContext.Provider>
  );
};

export const useTasksState = () => {
  return useContext(TasksContext);
};

export const useTasksStateDispatch = () => {
  return useContext(TasksDispatchContext);
};
