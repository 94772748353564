import $ from 'jquery';
import { API_URL } from './constants';
import { cookieIsExpired, deptNameToDeptId, getCookie } from './helpers';
import moment from 'moment';

// TASK ROUTES
// ===========

export const getTasksForLoggedInEmployee = () => {
  return $.get(API_URL + '/tasks/employee/' + getCookie('keno_id') + '.json');
};

export const getTasksByDeptId = id => {
  return $.get(API_URL + '/tasks/department/' + id);
};

export const getTasksByJobId = id => {
  return $.get(API_URL + '/tasks/job/all/' + id);
};

// Returns an object with "recent" and "all" arrays of time entries
// No idea why the backend is set up so that recent=false returns recent... ah well
export const getOpenTasksByJobId = id => {
  return $.get(
    API_URL + '/tasks/job/' + id + '.json?task_status=Open&recent=false'
  );
};

export const getTaskDetailsById = id => {
  return $.get(API_URL + '/tasks/detail/' + id + '.json');
};

export const getTaskInfoById = id => {
  return $.get(API_URL + '/tasks/info/' + id + '.json');
};

export const getTaskCommentsById = id => {
  return $.get(API_URL + '/task_comments/task/' + id);
};

export const saveNewTask = data => {
  return $.post(API_URL + '/tasks/save.json', data);
};

export const updateTaskDetail = (id, newDetail) => {
  const data = { task_detail: newDetail };
  return $.post(API_URL + '/tasks/update/task_detail/' + id, data);
};

export const updateTaskStatus = (id, newStatus) => {
  const data = { task_status: newStatus };
  return $.post(API_URL + '/tasks/update/status/' + id, data);
};

export const updateTaskProgress = (id, newProgress) => {
  const data = { task_progress: newProgress };
  return $.post(API_URL + '/tasks/update/progress/' + id, data);
};

export const updateTaskDepartment = (id, newDepartmentName) => {
  const data = {
    department_id: deptNameToDeptId(newDepartmentName),
    task_department: newDepartmentName,
  };
  return $.post(API_URL + '/tasks/update/department/' + id, data);
};

export const updateTaskOwner = (taskId, agentId) => {
  const data = { agent_id: agentId };
  return $.post(API_URL + '/tasks/update/owner/' + taskId, data);
};

export const updateTaskDueDate = (taskId, dueDate) => {
  const data = {
    task_duedate: moment(dueDate).format('YYYY-MM-DD 00:00:00'),
  };
  return $.post(API_URL + '/tasks/update/due_date/' + taskId, data);
};

export const updateTaskHoursEstimated = (taskId, hoursEstimated) => {
  let data = {
    hours_estimated: hoursEstimated,
  };
  return $.post(API_URL + '/tasks/update/hours_estimated/' + taskId, data);
};

export const updateTaskHoursAdjusted = (taskId, hoursAdjusted) => {
  let data = {
    hours_adjusted: hoursAdjusted,
  };
  return $.post(API_URL + '/tasks/update/hours_adjusted/' + taskId, data);
};

export const createTaskComment = (taskId, comment, employeeId) => {
  const data = {
    task_comment: {
      task_id: Number(taskId),
      comment: comment,
      employee_id: Number(employeeId),
    },
  };

  return $.post(API_URL + '/task_comments/add_comment.json', data);
};

export const getTaskTodosByTaskId = id => {
  return $.get(API_URL + '/task_todos/task/' + id + '.json');
};

export const createTaskTodo = taskData => {
  return $.post(API_URL + '/task_todos/create', taskData);
};

export const updateTodoCompletionStatus = (id, complete) => {
  const data = { complete: complete };
  return $.post(API_URL + '/task_todos/update_complete/' + id, data);
};

export const deleteTodo = id => {
  return $.ajax({
    url: API_URL + '/task_todos/delete/' + id,
    type: 'DELETE',
    success: function (result) {
      return result;
    },
  });
};

export const updateTaskTodoTitle = (id, title) => {
  return $.ajax({
    url: API_URL + '/task_todos/update_title/' + id,
    type: 'PUT',
    data: { title: title },
    success: function (result) {
      return result;
    },
  });
};

// JOB ROUTES
// ==========

export const getRecentJobs = () => {
  return $.get(
    API_URL + '/time_records/recent_jobs/' + getCookie('keno_id') + '.json'
  );
};

export const getAllJobs = () => {
  return $.get(API_URL + '/jobs/client/all/all.json');
};

export const getOpenJobs = () => {
  return $.get(API_URL + '/jobs/client/all/open.json');
};

export const getJobsByClientId = id => {
  return $.get(API_URL + '/jobs/client/all/' + id);
};

export const getJobDetailsById = id => {
  return $.get(API_URL + '/jobs/details/' + id + '.json');
};

export const getJobById = id => {
  return $.get(API_URL + '/jobs/results/' + id + '.json');
};

export const getOpenTasksCountByJobId = id => {
  return $.get(API_URL + '/tasks/count/' + id + '.json');
};

export const getJobClientDetailsByJobId = id => {
  return $.get(API_URL + '/jobs/client/details/' + id + '.json');
};

export const updateHoursBid = (id, hoursBid) => {
  const data = { hours_bid: hoursBid };
  return $.post(API_URL + '/jobs/update/hours_bid/' + id, data);
};

export const updateJobClass = (id, jobClass) => {
  const data = { job_class: jobClass };
  return $.post(API_URL + '/jobs/update/job_class/' + id, data);
};

export const updateJobBillingType = (id, jobBillingType) => {
  const data = { job_billing_type: jobBillingType };
  return $.post(API_URL + '/jobs/update/job_billing_type/' + id, data);
};

export const updateJobCategory = (id, jobCategory) => {
  const data = { job_category: jobCategory };
  return $.post(API_URL + '/jobs/update/job_category/' + id, data);
};

// JOB COMMENT ROUTES
// ==================
export const getJobCommentsByJobId = jobId => {
  return $.get(API_URL + '/job_comments/' + jobId);
};

export const createJobComment = (jobId, comment, employeeId) => {
  const data = {
    job_comment: {
      job_id: Number(jobId),
      comment: comment,
      employee_id: Number(employeeId),
    },
  };

  return $.post(API_URL + '/job_comments/create_comment.json', data);
};

// EMPLOYEE ROUTES
// ===============

export const getActiveEmployeesWithGoals = () => {
  return $.get(API_URL + '/goals/active_employees');
};

export const getAllEmployeesWithGoals = () => {
  return $.get(API_URL + '/goals/all_employees');
};

export const getActiveEmployees = () => {
  return $.get(API_URL + '/agents/active');
};

export const updateEmployeeUserLevel = (id, data) => {
  return $.ajax({
    url: API_URL + '/employees/update/userlevel/' + id + '.json',
    type: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
    accepts: 'application/json, text/plain, */*',
    dataType: 'json',
  });
};

export const getAccessLevelByEmployeeId = id => {
  return $.get(API_URL + '/employees/userLevel/' + id);
};

export const getEmployeeInfoById = id => {
  return $.get(API_URL + '/employees/info/' + id);
};

// CLIENT ROUTES
// =============

export const getActiveClients = () => {
  return $.get(API_URL + '/clients/active');
};

export const getRecentClients = id => {
  return $.get(API_URL + '/clients/recent/' + id);
};

// RECEIVABLES ROUTES
// ==================

export const getTotalReceivables = () => {
  return $.get(
    API_URL + '/receivables/total/' + getCookie('keno_id') + '.json'
  );
};

export const getReceivablesByEmployeeId = id => {
  return $.get(API_URL + '/receivables/' + id);
};

// TIME RECORD ROUTES
// ==================

export const getTimeEntryDataById = id => {
  return $.get(API_URL + '/time_records/' + id);
};

export const createTimeRecord = eventData => {
  const isExpired = cookieIsExpired('keno_auth');

  if (isExpired) {
    console.log('auth cookie is expired. Returning false');
    // Return a promise that resolves to false
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }

  return $.post(API_URL + '/time_records/save.json', eventData);
};

export const updateTimeRecord = (id, eventData) => {
  const isExpired = cookieIsExpired('keno_auth');

  if (isExpired) {
    console.log('auth cookie is expired. Returning false');
    // Return a promise that resolves to false
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }

  return $.post(API_URL + '/time_records/' + id + '.json', eventData);
};

export const deleteTimeRecord = id => {
  const isExpired = cookieIsExpired('keno_auth');

  if (isExpired) {
    console.log('auth cookie is expired. Returning false');
    // Return a promise that resolves to false
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }

  return $.post(API_URL + '/time_records/delete/' + id);
};

export const getTotalWeekHoursByEmployeeId = (id, start, end) => {
  return $.get(
    API_URL +
      '/time_records/total_week_hours/' +
      id +
      '?start=' +
      start +
      '&end=' +
      end
  );
};

export const getTotalCategoryHoursByEmployeeId = (id, start, end) => {
  return $.get(
    API_URL +
      '/time_records/total_category_hours/' +
      id +
      '?start=' +
      start +
      '&end=' +
      end
  );
};

export const getAgencyTimeRecordsOverRange = (start, end, active_employees) => {
  const activeEmployeesString = active_employees?.join('&active_employees[]=');

  return $.get(
    API_URL +
      '/time_records/agency_records_over_range/' +
      '?start=' +
      start +
      '&end=' +
      end +
      '&active_employees[]=' +
      activeEmployeesString
  );
};

export const getDailyTimeRecords = (date, tzOffsetString) => {
  return $.get(
    API_URL +
      '/time_records/daily_records/' +
      getCookie('keno_id') +
      '?date=' +
      date +
      '&custom_tz_offset=' +
      tzOffsetString
  );
};

// CAPACITY ROUTES
// ===============

export const getCapacityRangeByEmployeeId = (id, start, end) => {
  return $.get(
    API_URL +
      '/capacity/range_for_employee/' +
      id +
      '?start=' +
      start +
      '&end=' +
      end
  );
};

export const getAgencyCapacityRange = (start, end) => {
  return $.get(
    API_URL + '/capacity/range_for_agency/?start=' + start + '&end=' + end
  );
};

export const getAllCapacityForEmployeeById = id => {
  return $.get(API_URL + '/capacity/all_for_employee/' + id);
};

export const updateCapacityRangeByEmployeeId = (
  id,
  start,
  end,
  fte,
  department
) => {
  return $.post(
    API_URL +
      '/capacity/update_range/' +
      id +
      '?start=' +
      start +
      '&end=' +
      end +
      '&fte=' +
      fte +
      '&department=' +
      department
  );
};

export const deleteCapacityRangeByEmployeeId = (id, start, end) => {
  return $.post(
    API_URL + '/capacity/delete_range/' + id + '?start=' + start + '&end=' + end
  );
};

export const getCapacityAgencySummaryByDate = dateString => {
  return $.get(API_URL + '/capacity/agency_summary/' + dateString);
};

// the data argument should be an array of capacity objects formatted like this:
// {
//   agent_code: agentInitials,
//   agent_id: employeeId,
//   fte_date: String(day.format('YYYY-MM-DD')),
//   day_of_week: String(day.day()),
//   department: department,
//   first_name: firstName,
//   last_name: lastName,
//   fte: fte,
// }
export const createCapacityRange = data => {
  return $.post(API_URL + '/capacity/create_range', JSON.stringify(data));
};

// OTHER ROUTES
// ============

export const getWorkTypesByJobId = id => {
  return $.get(API_URL + '/work_types/job/' + id);
};

export const getDepartmentGoalsById = id => {
  return $.get(API_URL + '/departments/goals/' + id + '.json');
};

export const getDepartmentTimePerformanceData = (start, end, dept_id) => {
  const start_date = moment(start).format('YYYY-MM-DD');
  const end_date = moment(end).format('YYYY-MM-DD');

  return $.get(
    API_URL +
      '/time_performance/get_dept_time_performance' +
      '?start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&dept_id=' +
      dept_id
  );
};

export const getAgencyTimePerformanceData = (start, end) => {
  const start_date = moment(start).format('YYYY-MM-DD');
  const end_date = moment(end).format('YYYY-MM-DD');

  return $.get(
    API_URL +
      '/time_performance/get_agency_time_performance' +
      '?start_date=' +
      start_date +
      '&end_date=' +
      end_date
  );
};
