import { getCapacityRangeByEmployeeId } from './api';
import {
  AGENCY_TZ,
  DEPARTMENTS,
  DONUT_JOB_CATEGORIES,
  STATUSES,
} from './constants';
import S from './styles';
import moment from 'moment';
import confetti from 'canvas-confetti';

/**
 * Given the cookie name, returns the cookie value
 * @param {string} cname
 * @returns
 */
export const getCookie = cname => {
  if (typeof document !== `undefined`) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let allCookies = decodedCookie.split(';');
    let foundCookie = '';
    allCookies.forEach(cookie => {
      if (cookie.includes(name)) {
        foundCookie = cookie.split('=')[1];
      }
    });
    if (foundCookie === '')
      console.log(
        `Returning foundCookie as an empty string (looking for ${cname})`
      );
    return foundCookie;
  }

  console.log('There was no found cookie');
  return '';
};

/**
 * Creates a cookie given a name, value, and days until expiration
 * @param {string} name
 * @returns
 */
export const createCookie = (name, value, days) => {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  } else {
    expires = '';
  }
  document.cookie = name + '=' + value + expires + '; path=/';
};

/**
 * Deletes a cookie if it exists
 * @param {string} name
 * @returns
 */
export const deleteCookie = name => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

// Given a cookie name, check if it exists
// If it exists, check if it is expired
// Return true if the cookie does not exist or is expired
export const cookieIsExpired = cookieName => {
  const cookie = getCookie(cookieName);
  if (cookie === '') {
    return true;
  } else {
    return false;
  }
};

/**
 * A simple function for abbreviating job categories
 * @param {string} category
 * @returns
 */
export const abbrevJobCategory = category => {
  return category === 'Client Work'
    ? 'CW'
    : category === 'Intellectual Property'
    ? 'IP'
    : category === 'Business Development'
    ? 'BD'
    : category === 'Professional Development'
    ? 'PD'
    : category === 'Operations'
    ? 'OP'
    : category === 'Overhead'
    ? 'OV'
    : category === 'Community Service'
    ? 'CS'
    : category === 'Personal'
    ? 'Personal'
    : category;
};

/**
 * Generic sort method for arrays of task/job objects
 * @param {*} list
 * @param {*} column
 * @param {*} direction
 * @returns
 */
export const dynamicSort = (list, column, direction) => {
  const compare = (a, b) => {
    let sortableA, sortableB;
    switch (column) {
      case 'client_abbrev':
        sortableA = a.client_abbrev;
        sortableB = b.client_abbrev;
        break;
      case 'job_code_name':
        sortableA = a.job_code_name
          ? a.job_code_name
          : a.job_code + ' ' + a.job_name;
        sortableB = b.job_code_name
          ? b.job_code_name
          : b.job_code + ' ' + b.job_name;
        break;
      case 'task_detail':
        sortableA = a.task_detail.toLowerCase();
        sortableB = b.task_detail.toLowerCase();
        break;
      case 'hrs_remaining':
        sortableA = calcTaskHrsRem(a);
        sortableB = calcTaskHrsRem(b);
        break;
      case 'due_date':
        sortableA = moment(a.due_date).format('YYYY-MM-DD');
        sortableB = moment(b.due_date).format('YYYY-MM-DD');
        break;
      case 'priority':
        sortableA = PriorityToNumber(a.priority);
        sortableB = PriorityToNumber(b.priority);
        break;
      case 'job_category':
        sortableA = JobCategoryToNumber(a.job_category);
        sortableB = JobCategoryToNumber(b.job_category);
        break;
      case 'job_billing_type':
        sortableA = a.job_billing_type;
        sortableB = b.job_billing_type;
        break;
      case 'last_used':
        sortableA = moment(a.last_used).format('YYYY-MM-DD');
        sortableB = moment(b.last_used).format('YYYY-MM-DD');
        break;
      case 'task_department':
        sortableA = a.task_department;
        sortableB = b.task_department;
        break;
      case 'task_agentcode':
        sortableA = a.task_agentcode;
        sortableB = b.task_agentcode;
        break;
      case 'task_hoursestimated':
        sortableA = Number(a.task_hoursestimated);
        sortableB = Number(b.task_hoursestimated);
        break;
      case 'hours_logged':
        sortableA = Number(a.hours_logged);
        sortableB = Number(b.hours_logged);
        break;
      case 'task_duedate':
        sortableA = moment(a.task_duedate).format('YYYY-MM-DD');
        sortableB = moment(b.task_duedate).format('YYYY-MM-DD');
        break;
      case 'milestone':
        sortableA = a.milestone;
        sortableB = b.milestone;
        break;
      case 'task_priority':
        sortableA = PriorityToNumber(a.task_priority);
        sortableB = PriorityToNumber(b.task_priority);
        break;
      default:
        break;
    }

    if (typeof sortableA === 'number') {
      if (direction === 'asc') {
        return sortableA - sortableB;
      }
      if (direction === 'desc') {
        return sortableB - sortableA;
      }
    } else {
      if (direction === 'asc') {
        if (sortableA > sortableB) {
          return 1;
        }
        if (sortableA < sortableB) {
          return -1;
        }
        return 0;
      }
      if (direction === 'desc') {
        if (sortableA < sortableB) {
          return 1;
        }
        if (sortableA > sortableB) {
          return -1;
        }
        return 0;
      }
    }
  };
  return list.sort(compare);
};

export const PriorityToNumber = status => {
  return [...STATUSES].reverse().indexOf(status);
};

export const JobCategoryToNumber = cat => {
  const cats = [
    'Client Work',
    'Business Development',
    'Intellectual Property',
    'Professional Development',
    'Operations',
    'Overhead',
    'Community Service',
    'Personal',
  ];
  return cats.indexOf(cat);
};

export const roundToNearestQuarter = number => {
  number =
    number === '' || number === undefined || number === null
      ? 0
      : timeStringToFloat(number);
  return (Math.round(number * 4) / 4).toFixed(2);
};

// Converts a time from 10:15 to 10.25
export const timeStringToFloat = time => {
  if (isNaN(time)) {
    if (time?.includes(':')) {
      return Number(time.split(':')[0]) + Number(time.split(':')[1]) / 60.0;
    } else {
      return parseFloat(time);
    }
  }
  return Number(time);
};

// Converts a time from 10.25 to 10:15
export const floatToTimeString = time => {
  const hours = Math.floor(time);
  let minutes = (time - Math.floor(time)) * 60;

  if (String(minutes).length === 1) {
    minutes = `0${minutes}`;
  }
  if (String(minutes).length > 2) {
    minutes = String(minutes).slice(0, 2);
  }
  return `${hours}:${minutes}`;
};

/**
 * A simple function for getting job category colors
 * @param {string} category
 * @returns
 */
export const getColorfromJobCategory = category => {
  return category === 'Client Work'
    ? S.c_client_work
    : category === 'Intellectual Property'
    ? S.c_intellectual_property
    : category === 'Business Development'
    ? S.c_business_development
    : category === '' || category === 'Untracked Hours'
    ? S.c_untracked_hours
    : category === 'Unassigned'
    ? S.c_error
    : category === 'Personal'
    ? S.c_white_almost
    : S.c_support;
};

// This method sorts the resp data from getTotalCategoryHoursByEmployeeId()
// and combines all support categoryies under 1 key
export const formatCategoryHoursResp = data => {
  let output = {};

  if (data['Unassigned']) {
    output = { Unassigned: { ...data['Unassigned'] } };
  }
  if (data['Client Work']) {
    output = { ...output, 'Client Work': { ...data['Client Work'] } };
  }
  if (data['Business Development']) {
    output = {
      ...output,
      'Business Development': { ...data['Business Development'] },
    };
  }
  if (data['Intellectual Property']) {
    output = {
      ...output,
      'Intellectual Property': { ...data['Intellectual Property'] },
    };
  }

  output = { ...output, Support: { total_hours: 0 } };

  Object.keys(data).forEach(k => {
    if (
      k !== 'Client Work' &&
      k !== 'Business Development' &&
      k !== 'Intellectual Property' &&
      k !== 'Unassigned' &&
      k !== 'Personal'
    ) {
      output = {
        ...output,
        Support: {
          total_hours: output['Support'].total_hours + data[k].total_hours,
        },
      };
    }
  });

  return output;
};

/**
 * A simple function checking if item is billable based on job category
 * @param {string} category
 * @returns
 */
export const getBillableFromJobCategory = category => {
  return category === 'Client Work' ||
    category === 'Intellectual Property' ||
    category === 'Business Development'
    ? true
    : false;
};

// Used to check if daylight savings is happening
// eslint-disable-next-line no-extend-native
Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 0, 1);
  var jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};
// eslint-disable-next-line no-extend-native
Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

export const isDaylightSavings = date => {
  if (date) {
    var date = new Date(moment(date).format('YYYY-MM-DD'));
    if (date.isDstObserved()) {
      return true;
    }
  } else {
    var today = new Date();
    if (today.isDstObserved()) {
      return true;
    } else {
      return false;
    }
  }
};

export const getLocalTzOffset = () => {
  const hours = (new Date().getTimezoneOffset() / 60) * -1;
  // 300 => 5
  // -300 => -5
  // 630 => 10.5
  // -630 => -10.5

  let offsetString = String(hours);

  if (!offsetString.includes('.')) {
    if (offsetString.length === 1) {
      return `0${offsetString}:00`;
    } else {
      if (offsetString.length === 2 && offsetString.includes('-')) {
        return `-0${offsetString[1]}:00`;
      } else {
        return `${offsetString}:00`;
      }
    }
  } else {
    let minutes = (Number(offsetString.split('.')[1]) / 10) * 60;
    return `${offsetString}:${minutes}`;
  }
};

// Helper that adds the tz shift to a datestring
export const addTzShiftToDatestring = (dateString, timezoneData) => {
  const isDS = isDaylightSavings(dateString);

  return moment(dateString)
    .add(isDS ? timezoneData.dsOffsetNum : timezoneData.offsetNum, 'hours')
    .format('YYYY-MM-DD HH:mm:ss');
};

// Helper that subtracts the tz shift from a datestring
export const subtractTzShiftFromDatestring = (dateString, timezoneData) => {
  const isDS = isDaylightSavings(dateString);
  return moment(dateString)
    .subtract(isDS ? timezoneData.dsOffsetNum : timezoneData.offsetNum, 'hours')
    .format('YYYY-MM-DD HH:mm:ss');
};

export const formatTime = inputTotal => {
  const time = String(inputTotal);
  if (time.includes('.')) {
    const hours = time.split('.')[0];
    const minutes = String((Number(time.split('.')[1]) / 10) * 60).slice(0, 2);
    return `${hours}:${minutes}`;
  } else {
    return `${inputTotal}:00`;
  }
};

export const deptNameToDeptId = deptName => {
  switch (deptName) {
    case 'Admin':
      return 1;
    case 'Creative':
      return 2;
    case 'Strategy':
      return 3;
    case 'PR':
      return 4;
    case 'Account':
      return 5;
    case 'Digital':
      return 6;
    case 'Media':
      return 7;
    case 'Analytics':
      return 8;
    default:
      return -1;
  }
};

export const deptIdToDeptName = deptId => {
  let name = '';
  Object.keys(DEPARTMENTS).forEach(key => {
    if (DEPARTMENTS[key] === deptId) {
      name = key;
    }
  });
  return name;
};

// Given a start and end date,
// Returns the number of days in the window that are Mon-Fri
export const getExpectedNumberOfWorkDaysInRange = (startRange, endRange) => {
  let adjustedStart = startRange,
    adjustedEnd = endRange;
  if (moment(startRange) > moment(endRange)) {
    adjustedStart = endRange;
    adjustedEnd = startRange;
  }

  const s = moment(adjustedStart);
  const e = moment(adjustedEnd);
  let expectedCount = 0;

  for (let i = 0; i < e.diff(s, 'days') + 1; i++) {
    if (
      moment(e).subtract(i, 'd').day() >= 1 &&
      moment(e).subtract(i, 'd').day() <= 5
    ) {
      expectedCount += 1;
    }
  }

  return expectedCount;
};

// Given a start of range and end of range and employee id,
// calculate the expected hours worked for employee via capacity table
export const getTimeframeExpectedHoursByEmployeeId = (
  employeeId,
  start,
  end
) => {
  return getCapacityRangeByEmployeeId(employeeId, start, end)
    .then(timeframeData => {
      const numberOfDays = timeframeData.length;
      let fteSum = 0;

      timeframeData.forEach(day => {
        fteSum += Number(day.fte);
      });

      const avgFte = fteSum / numberOfDays;

      let startDateInRange = false;
      if (
        numberOfDays !== getExpectedNumberOfWorkDaysInRange(start, end) &&
        timeframeData.length > 0
      ) {
        startDateInRange = timeframeData[0];
        timeframeData.forEach(entry => {
          if (moment(entry.fte_date) < moment(startDateInRange.fte_date)) {
            startDateInRange = entry;
          }
        });
      }

      return {
        expectedDaysWithCapacity: getExpectedNumberOfWorkDaysInRange(
          start,
          end
        ),
        actualDaysWithCapacity: numberOfDays,
        expectedHours: isNaN(avgFte * numberOfDays * 8.0)
          ? 0.0
          : avgFte * numberOfDays * 8.0,
        startDateInRange: startDateInRange,
        timeframeData: timeframeData,
        employeeId: employeeId,
      };
    })
    .catch(err => {
      console.error('There was an error fetching employee capacity: ', err);
    });
};

// An external HTML render method for custom chart.js tooltips
// Code primarily taken from: https://www.chartjs.org/docs/latest/configuration/tooltip.html
export const externalChartTooltip = context => {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function (body, i) {
      innerHtml += '<tr><td><span>' + body + ' hrs</span></td></tr>';
    });
    innerHtml += '</tbody>';

    let tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 100;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.backgroundColor = S.c_white;
  tooltipEl.style.border = `1px solid ${S.c_border}`;
  tooltipEl.style.borderRadius = '5px';
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.padding = '10px';
  tooltipEl.style.pointerEvents = 'none';
};

export const getDayOfWeekFromIndex = i => {
  switch (i) {
    case 0: {
      return 'Sunday';
    }
    case 1: {
      return 'Monday';
    }
    case 2: {
      return 'Tuesday';
    }
    case 3: {
      return 'Wednesday';
    }
    case 4: {
      return 'Thursday';
    }
    case 5: {
      return 'Friday';
    }
    case 6: {
      return 'Saturday';
    }
    default: {
      return 'Invalid Day';
    }
  }
};

// Start and end should be strings 'YYYY-MM-DDTHH:mm:ss' in the tz that the calendar is currently displaying.
// tzData should be the selected tz object for the currently displayed tz
export const minutesOverlappingWithAgencyMidnight = (start, end, tzData) => {
  // Convert from displayed tz to the agency tz
  const startInAgencyTz = addTzShiftToDatestring(
    subtractTzShiftFromDatestring(start, tzData),
    AGENCY_TZ
  );
  const endInAgencyTz = addTzShiftToDatestring(
    subtractTzShiftFromDatestring(end, tzData),
    AGENCY_TZ
  );

  // Check if the dates are not the same
  if (
    moment(startInAgencyTz).format('YYYY-MM-DD') !==
    moment(endInAgencyTz).format('YYYY-MM-DD')
  ) {
    // Return the number of minutes that it goes over midnight
    const minutesOverlapping =
      moment(endInAgencyTz).minutes() + moment(endInAgencyTz).hours() * 60 + 1; // The +1 is so that the new time will be 11:59pm
    return minutesOverlapping;
  }
  // Otherwise, return 0
  return 0;
};

export const sortCapacityArrayByDate = capacityArray => {
  return capacityArray.sort((a, b) => {
    if (a.fte_date > b.fte_date) {
      return 1;
    } else if (a.fte_date < b.fte_date) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const customConfetti = () => {
  return confetti({
    angle: randomInRange(55, 125),
    spread: randomInRange(50, 170),
    particleCount: randomInRange(50, 100),
    origin: { y: 0.6 },
  });
};

export const sortArrayOfObjectsByKeyString = (array, keyString) => {
  return array.sort((a, b) => {
    return a[keyString]?.toLowerCase() > b[keyString]?.toLowerCase()
      ? 1
      : a[keyString]?.toLowerCase() < b[keyString]?.toLowerCase()
      ? -1
      : 0;
  });
};

export const calcTaskHrsRem = task => {
  if (task?.task_hoursestimated && task?.hours_logged) {
    if (task?.hours_adjusted) {
      return (
        timeStringToFloat(task?.task_hoursestimated) -
        timeStringToFloat(task?.hours_logged) +
        timeStringToFloat(task?.hours_adjusted)
      );
    } else if (task?.task_hoursadjusted) {
      return (
        timeStringToFloat(task?.task_hoursestimated) -
        timeStringToFloat(task?.hours_logged) +
        timeStringToFloat(task?.task_hoursadjusted)
      );
    } else {
      return (
        timeStringToFloat(task?.task_hoursestimated) -
        timeStringToFloat(task?.hours_logged)
      );
    }
  } else if (task?.task_hoursestimated) {
    return timeStringToFloat(task.task_hoursestimated);
  } else {
    console.log('DEBUG TASK: ', task);
    return 0;
  }
};

export const formatCategoryHoursForChart = hoursObject => {
  let tempObject = {};
  Object.keys(hoursObject).forEach(category => {
    if (
      category === 'Operations' ||
      category === 'Overhead' ||
      category === 'Community Service' ||
      category === 'Professional Development'
    ) {
      if (tempObject['Support']) tempObject['Support'] += hoursObject[category];
      else tempObject['Support'] = hoursObject[category];
    } else {
      tempObject[category] = hoursObject[category];
    }
  });

  // Now, sort the keys of the object based on JOB_CATEGORIES constant
  let sortedObject = {};
  Object.keys(tempObject)
    .sort((a, b) => {
      return DONUT_JOB_CATEGORIES.indexOf(a) - DONUT_JOB_CATEGORIES.indexOf(b);
    })
    .forEach(key => {
      if (key !== 'Personal') sortedObject[key] = tempObject[key];
    });

  return sortedObject;
};

// This is a method specifically for the time_performance page
// It takes in an employee object and a category name and returns the employee goal for that category
export const getEmployeeCategoryGoal = (category, employee) => {
  switch (category) {
    case 'Client Work':
      return String((Number(employee.goals.cw) * 100).toFixed(0)) + '%';
    case 'Business Development':
      return String((Number(employee.goals.bd) * 100).toFixed(0)) + '%';
    case 'Intellectual Property':
      return String((Number(employee.goals.ip) * 100).toFixed(0)) + '%';
    case 'Support':
      return (
        String(
          (
            (Number(employee.goals.pd) +
              Number(employee.goals.ops) +
              Number(employee.goals.overhead) +
              Number(employee.goals.cs)) *
            100
          ).toFixed(0)
        ) + '%'
      );
    case 'Untracked Hours':
      return '0%';
    default:
      return 'N/A';
  }
};
