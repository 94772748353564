import React from 'react';
import { TimeEntryProvider } from './src/context/TimeEntryContext';
import { GeneralStateProvider } from './src/context/GeneralContext';
import { TasksProvider } from './src/context/TasksContext';
import { JobsProvider } from './src/context/JobsContext';
import { OpenJobsProvider } from './src/context/OpenJobsContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export const wrapPageElement = ({ element }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <OpenJobsProvider>
        <TimeEntryProvider>
          <TasksProvider>
            <JobsProvider>
              <GeneralStateProvider>{element}</GeneralStateProvider>
            </JobsProvider>
          </TasksProvider>
        </TimeEntryProvider>
      </OpenJobsProvider>
    </LocalizationProvider>
  );
};
