import React, { createContext, useContext, useReducer } from 'react';
import { dynamicSort } from '../helpers';

export const JobsContext = createContext(null);
export const JobsDispatchContext = createContext(null);

const initialJobsContext = [];

const jobsReducer = (jobs, action) => {
  switch (action.type) {
    case 'sort': {
      return dynamicSort(
        dynamicSort(jobs, 'last_used', 'desc'),
        action.payload.column,
        action.payload.direction
      );
    }
    case 'setJobs': {
      return action.payload;
    }
    default: {
      return jobs;
    }
  }
};

export const JobsProvider = ({ children }) => {
  const [jobs, dispatch] = useReducer(jobsReducer, initialJobsContext);

  return (
    <JobsContext.Provider value={jobs}>
      <JobsDispatchContext.Provider value={dispatch}>
        {children}
      </JobsDispatchContext.Provider>
    </JobsContext.Provider>
  );
};

export const useJobsState = () => {
  return useContext(JobsContext);
};

export const useJobsStateDispatch = () => {
  return useContext(JobsDispatchContext);
};
