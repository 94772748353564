import S from './styles';

export const API_URL = process.env.BACKEND_API_URL;

export const DEPARTMENTS = {
  Account: 5,
  Admin: 1,
  Analytics: 8,
  Creative: 2,
  Digital: 6,
  Media: 7,
  PR: 4,
  Strategy: 3,
};

export const CHANGELOG_KEY = 'CHANGELOG_';

export const STATUSES = [
  'Approved',
  'To Approve',
  'Validating',
  'Doing',
  'Ready',
  'Blocked',
  'Backlog',
];

export const JOB_BILLING_TYPES = ['Retainer', 'Project', 'Pro Bono', 'Spec'];

export const JOB_CATEGORIES = [
  'Client Work',
  'Business Development',
  'Intellectual Property',
  'Professional Development',
  'Operations',
  'Overhead',
  'Community Service',
  'Personal',
];

export const DONUT_JOB_CATEGORIES = [
  'Unassigned',
  'Client Work',
  'Business Development',
  'Intellectual Property',
  'Support',
  'Untracked Hours',
];

export const JOB_CLASSES = ['DT', 'Media', 'PR', 'WD'];

export const DONUT_COLORS = [
  S.c_client_work,
  S.c_business_development,
  S.c_intellectual_property,
  S.c_support,
  S.c_unassigned_hours,
  S.c_untracked_hours,
];

export const CATEGORY_LABELS = [
  'Client Work',
  'Business Development',
  'Intellectual Property',
  'Support',
  'Unassigned',
  'Untracked Hours',
];

export const AGENCY_TZ = {
  label: 'Central Time',
  name: '',
  offset: '-06:00',
  dsOffset: '-05:00',
  offsetNum: -6,
  dsOffsetNum: -5,
};

export const TIMEZONES = [
  {
    label: 'Hawaii Time',
    name: '',
    offset: '-10:00',
    dsOffset: '-09:00',
    offsetNum: -10,
    dsOffsetNum: -9,
  },
  {
    label: 'Alaska Time',
    name: '',
    offset: '-09:00',
    dsOffset: '-08:00',
    offsetNum: -9,
    dsOffsetNum: -8,
  },
  {
    label: 'Pacific Time',
    name: '',
    offset: '-08:00',
    dsOffset: '-07:00',
    offsetNum: -8,
    dsOffsetNum: -7,
  },
  {
    label: 'Mountain Time',
    name: '',
    offset: '-07:00',
    dsOffset: '-06:00',
    offsetNum: -7,
    dsOffsetNum: -6,
  },
  {
    label: 'Central Time',
    name: '',
    offset: '-06:00',
    dsOffset: '-05:00',
    offsetNum: -6,
    dsOffsetNum: -5,
  },
  {
    label: 'Eastern Time',
    name: '',
    offset: '-05:00',
    dsOffset: '-04:00',
    offsetNum: -5,
    dsOffsetNum: -4,
  },
];
